import React, { useEffect, useState } from "react";
import { NavLink , Link } from "react-router-dom"; // Import NavLink
import axios from "axios";

const Header = () => {
    const [logoUrl, setLogoUrl] = useState("");

    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                if (response.data.photos && response.data.photos.length > 0) {
                    setLogoUrl(response.data.photos[0].url);
                }
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };

        fetchLogo();
    }, [API_URL_PHOTO]);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header id="header" className={`header-main ${scrolled ? "bg-header" : ""}`}>
            <nav className="navbar navbar-expand-md">
                <div className="container">
                    <NavLink className="logo" to="/">
                        <img src={logoUrl || "./logo.png"} alt="logo" />
                    </NavLink>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse pb-2 justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/about"
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                >
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/service"
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                >
                                    Service
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink
                                    to="/shop"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={({ isActive }) => (isActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle")}
                                >
                                    Shop
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/shop">Shop</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/shop-detail-page">Shop Detail page</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink
                                    to="/podcast"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={({ isActive }) => (isActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle")}
                                >
                                    Podcast
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/podcast">Podcast</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/podcast-detail-page">Podcast detail page</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink
                                    to="/blog"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={({ isActive }) => (isActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle")}
                                >
                                    Blog
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/blog">Blog</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/blog-detail-page">Blog Detail Page</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                            <Link to="/login" className="btn theme_btn ms-0 ms-lg-4">Login / Register</Link>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
