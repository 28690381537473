import './setJQuery';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import BlogPage from "./pages/BlogPage";
import ShopPage from "./pages/ShopPage";
import ContactPage from "./pages/ContactPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import ShopDetailPage from "./pages/ShopDetailPage";
import PodcastDetailPage from "./pages/PodcastDetailPage";
import PodcastPage from "./pages/PodcastPage";
import Login from "./components/Login";
// import './assets/css/owl.carousel.min.css';
// import './assets/css/bootstrap.min.css';

import './assets/css/stellarnav.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

const App = () => {
    return (
        <Router>
          
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/service" element={<ServicePage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog-detail-page" element={<BlogDetailPage />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/shop-detail-page" element={<ShopDetailPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/podcast" element={<PodcastPage />} />
                <Route path="/podcast-detail-page" element={<PodcastDetailPage />} />
                <Route path="/login" element={<Login />} />

                </Routes>
            
        </Router>
    );
};

export default App;
