import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Services = () => {
  const [serviceOneData, setServiceOneData] = useState(null);
  const [serviceTwoData, setServiceTwoData] = useState(null);
  const [serviceThreeData, setServiceThreeData] = useState(null);
  const [serviceFourData, setServiceFourData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = [
    `${BASE_URL}/api/v1/topic/170`, // First Service
    `${BASE_URL}/api/v1/topic/171`, // Second Service
    `${BASE_URL}/api/v1/topic/172`, // Third Service (fixed the typo in the URL)
    `${BASE_URL}/api/v1/topic/173`, // Fourth Service
  ];
  

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const responses = await Promise.all(API_URL.map((url) => axios.get(url)));
        setServiceOneData(responses[0].data);
        setServiceTwoData(responses[1].data);
        setServiceThreeData(responses[2].data);
        setServiceFourData(responses[3].data);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServicesData();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <section className="sec_padding our-services">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="sec_padding our-services">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Define service titles and subtitles
  const titles = [
    "60 day <span className='stylish'>Shape Shifting</span>",
    "Holistic <span className='stylish'>Makeover</span>",
    "Monthly <span className='stylish'>ZenPreneur</span>",
    "7 minute <span className='stylish'>SMiracles</span>"
  ];

  const subtitles = [
    "Mastery Transformation",
    "Coaching 1:1",
    "Acceptances",
    "Mastery Transformation"
  ];

  // Extract details from each service
  const services = [
    serviceOneData?.topic[0],
    serviceTwoData?.topic[0],
    serviceThreeData?.topic[0],
    serviceFourData?.topic[0],
  ];

  // Gradient classes
  const gradientClasses = ["gradient-one", "gradient-two", "gradient-three", "gradient-four"];

  return (
    <section className="sec_padding our-services">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-4 mb-3">
            <h6 className="sub_heading text-center">Services</h6>
            <h2 className="sec_heading-stylish text-center">Our Offerings</h2>
            <span className="down-arrow">
              <i className="fa-solid fa-arrow-down-long"></i>
            </span>
          </div>

          {/* Loop through services and render each one */}
          {services.map((service, index) => (
            <div key={index} className="col-md-6">
              <div className="padding-top-item">
                <div className="service-item text-center">
                  <div className={`box-details ${gradientClasses[index]}`}>
                    <h5 className="Presents">
                      <span className="stylish">Michele </span>Presents
                    </h5>
                    {/* Static h3 and h4 with specific structure for h3 */}
                    <h3
                      className="title"
                      dangerouslySetInnerHTML={{ __html: titles[index] }}
                    />
                    <h4 className="sub-title">{subtitles[index]}</h4>
                  </div>
                  <div
                    className="pera"
                    dangerouslySetInnerHTML={{ __html: service?.details || "" }} // Render the service details dynamically
                  />
                  <Link to="/#" className="btn theme_btn">
                    Discover More here
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
