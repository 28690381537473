import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const BlogComponent = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL ;

  // Memoize the API URLs so that they don’t change on every render
  const API_URLS = useMemo(() => [
    `${BASE_URL}/api/v1/topic/192`,
    `${BASE_URL}/api/v1/topic/193`,
    `${BASE_URL}/api/v1/topic/194`,
  ], [BASE_URL]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const responses = await Promise.all(
          API_URLS.map((url) => axios.get(url))
        );
        const blogData = responses.map((response) => response.data.topic[0]);
        
        // Duplicate the first blog in the array
        const duplicatedBlogs = [...blogData];
        duplicatedBlogs.push(blogData[0]);

        setBlogs(duplicatedBlogs);
      } catch (err) {
        setError("Error fetching blogs");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [API_URLS]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {blogs.map((blog, index) => (
        <div className="col-md-6 mt-2" key={index}>
          <Link to="#" className="artical-box">
            <div className="img-artical">
              <img
                src={blog.photo_file || "assets/images/default-image.png"}
                alt={blog.title}
              />
            </div>
            <div className="content-artical">
              <span className="detail">
                by {blog.user.name} | {blog.date} |{" "}
                {blog.Joined_categories[0]?.title || "Uncategorized"}
              </span>
              <h3 className="title-artical">{blog.title}</h3>
              <p className="desc">
                {blog.details
                  ? blog.details.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + "..."
                  : "No description available."}
              </p>
              <Link to={`/blog/${blog.id}`} className="btn theme_btn mt-3">
                Read More
              </Link>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default BlogComponent;
