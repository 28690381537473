import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const About = () => {
    const [aboutData, setAboutData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL = `${BASE_URL}/api/v1/topic/189`;

    useEffect(() => {
        AOS.init();

        const fetchAboutData = async () => {
            try {
                const response = await axios.get(API_URL);
                setAboutData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAboutData();
    }, [API_URL]);

    if (loading) {
        return (
            <section className="sec_padding about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>Loading...</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    if (error) {
        return (
            <section className="sec_padding about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // Extract the fields from the API response
    // const fields = aboutData?.topic[0]?.fields || [];
    const photoFile = aboutData?.topic[0]?.fields?.find(field => field.type === 8)?.value || null;
    
    const paragraphOne = aboutData?.topic[0]?.fields?.find(field => field.title === "Paragraph_One")?.value || "";
    const paragraphTwo = aboutData?.topic[0]?.fields?.find(field => field.title === "Paragraph_two")?.value || "";

    return (
        <section className="sec_padding about-section">
            <div className="container">
                <div className="row">
                    <div data-aos="fade-left" className="col-xl-4 col-lg-4 col-md-6">
                        {/* Image Section */}
                        {photoFile && (
                            <div className="about-img">
                            <img src={aboutData?.topic[0]?.photo_file || "assets/images/Michele.png"} alt="Michele" />

                            </div>
                        )}
                    </div>
                    <div data-aos="fade-left" className="col-xl-8 col-lg-8 col-md-6">
                        {/* Title */}
                        <h2 className="sec_heading">
                            Meet <span className="sec_heading-stylish">Michele</span>
                        </h2>
                        {/* Paragraph One */}
                        {paragraphOne && (
                            <div className="pera" dangerouslySetInnerHTML={{ __html: paragraphOne }} />
                        )}
                        {/* Paragraph Two Below the Image */}
                        
                        {/* Link to My Story */}
                       
                    </div>
                    <div className="col-md-12">
                    {paragraphTwo && (
                            <div className="pera" dangerouslySetInnerHTML={{ __html: paragraphTwo }} />
                        )}
                        <div className="text-center">
                        <Link to="/mystory" className="btn theme_btn mt-3">My story</Link>
                        </div>
				</div>
                </div>
            </div>
        </section>
    );
};

export default About;
