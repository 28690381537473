import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const BlogSidebar = () => {
    return (
        <>

        <div className="blog-sidebar">
            <div className="searchbar">
                <input type="search" className="search-input" placeholder="Search"/>
                <div className="icon-submit"><i className="fas fa-search"></i></div>
            </div>

            <div className="about-feed feed">
                <h3 className="heading">About</h3>
                <div className="info">
                    <div className="img">
                        <img src="assets/images/Michele.png" alt=""/>
                    </div>
                    <div className="detail">

                        <h4 className="name">Michele</h4>
                        <p className="desination">Fitness Professional</p>

                    </div>

                </div>
                <p className="content">
                Welcome! I’m Michele Drake, an alternative health, wellness, and fitness professional with over 35 years of experience. 
                </p>

                <ul className="social">
                    <li>
                        <Link to="#"><i className="fa-solid fa-location-dot"></i></Link>
                        <span>Canada</span></li>
                    <li>
                    <Link to="#"><i className="fa-brands fa-instagram"></i></Link>
                    <Link to="#"><i className="fa-brands fa-whatsapp"></i></Link>
                    <Link to="#"><i className="fa-brands fa-facebook"></i></Link></li>
                

                </ul>
            </div>

            <div className="category feed">

                <h3 className="heading">Category</h3>
                <ul className="categoty-list">
                    <li><Link to="#">Fitness</Link></li>
                    <li><Link to="#">Health</Link></li>
                    <li><Link to="#">Wellness</Link></li>
                    <li><Link to="#">Workout</Link></li>
                </ul>

            </div>

            <div className="Popular-post feed ">

                <h3 className="heading">Popular Post</h3>
                <div className="PopularPost-list">
                    <Link to="#" className="item">

                        <div className="img">
                            <img src="/assets/images/blog-1.png" alt=""/>
                        </div>
                        <p className="content">Empowered Living Through Breathwork and Movement</p>

                    </Link>
                    <Link to="#" className="item">

                        <div className="img">
                            <img src="/assets/images/blog-2.png" alt=""/>
                        </div>
                        <p className="content">Inner Peace Pathways: The Power of Daily Yoga</p>

                    </Link>
                    <Link to="#" className="item">

                        <div className="img">
                            <img src="/assets/images/blog-3.png" alt=""/>
                        </div>
                        <p className="content">Flow into Balance: A Journey of Self-Discovery Through Yoga</p>

                    </Link>
                </div>

            </div>
        </div>
            

        </>
    );
};

export default BlogSidebar;
    