import React from "react";
import Header from "./Header";
import Banner from "./Banner";
import About from "./About";
import Philosophy from "./Philosophy";
import Services from "./Services";
import Transformation from "./Transformation";
import Testimonials from "./Testimonials";
import WhyChooseUs from "./WhyChooseUs";
import Blogs from "./Blogs";
import Footer from "./Footer";
import { LogoProvider } from "../contexts/LogoContext";


const Home = () => {
    return (
        <>
        <LogoProvider>
            <Header />
            <Banner />
            <About />
            <Philosophy />
            <Services />
            <Transformation />
            <Testimonials />
            <WhyChooseUs />
            <Blogs />
            <Footer />
            </LogoProvider>
        </>
    );
};

export default Home;
