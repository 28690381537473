import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom"; // Import Link from react-router-dom
import axios from "axios";

const WhyChooseUs = () => {
    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const VALUE_API_URL = `${BASE_URL}/api/v1/topic/186`; // New API URL for Value why choose us

    const [valueData, setvalueData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchValuewhychooseData = async () => {
          try {
            const response = await axios.get(VALUE_API_URL);
            setvalueData(response.data.topic[0]);
          } catch (err) {
            setError("Error fetching data");
            console.error(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchValuewhychooseData();
      }, [VALUE_API_URL]);

      if (loading) return <p>Loading...</p>;
      if (error) return <p>{error}</p>;

      const contentOne = valueData?.fields?.find(field => field.title === "content-one")?.value;
    const contentTwo = valueData?.fields?.find(field => field.title === "content-two")?.value;
    const contentThree = valueData?.fields?.find(field => field.title === "content-three")?.value;
    const contentFour = valueData?.fields?.find(field => field.title === "content-four")?.value;
    return (
        <>
     

        {/* <section className="sec_padding why-choose">

        <div className="container">

            <div className="row">
                <div className="col-lg">
                    <div className="content-top pr-md-5">
                        <h6 className="sub_heading">Value</h6>
                        <h2 className="sec_heading-stylish">Why Choose Us</h2>
                        <p className="title-pera pt-2 pb-3">
                            Imagine having Shape Shifting Mastery as your
                            ultimate ally – it’s like having your most brilliant,
                            inspiring, compassionate genie friend by your side!
                            With its boundless potential, it’s not just a skill, it’s a
                            dynamic force empowering you to conquer
                            challenges, manifest dreams, and unleash your
                            truest potential!
                        </p>
                    </div>
                    <img src="assets/images/why-choose-us.png" className="why-use-img" alt="why choose us"/>
                </div>
                <div className="col-lg-3">
                    <div className="item-why-us one">

                        <div className="icon-why">
                            <img src="assets/images/why-us-one.png" alt=""/>
                        </div>
                        <p clasName="pera">Led by Michele Drake, a
                            seasoned alternative
                            health and wellness
                            professional with 35 years
                            of expertise, our team
                            comprises highly
                            competent professionals
                            who have successfully
                            guided thousands towards
                            achieving their goals with
                            this protocol. With a
                            wealth of experience and
                            a proven track record, we
                            are dedicated to providing
                            top-notch guidance and
                            support to empower you
                            on your journey to
                            wellness and success.</p>

                    </div>
                </div>
                <div className="col-lg">

                    <div className="item-why-us-right  border-start border-theme">
                    <div className="item-why-us two border-bottom border-theme">

                        <div className="icon-why">
                            <img src="assets/images/why-us-two.png" alt=""/>
                        </div>
                        <p clasName="pera">Led by Michele Drake, a
                            seasoned alternative
                            health and wellness
                            professional with 35 years
                            of expertise, our team
                            comprises highly
                            competent professionals
                            who have successfully
                            guided thousands towards
                            achieving their goals with
                            this protocol. With a
                            wealth of experience and
                            a proven track record, we
                            are dedicated to providing
                            top-notch guidance and
                            support to empower you
                            on your journey to
                            wellness and success.</p>

                        </div>
                        <div className="item-why-us three">

                        <div className="icon-why">
                            <img src="assets/images/why-us-three.png" alt=""/>
                        </div>
                        <p clasName="pera">Led by Michele Drake, a
                            seasoned alternative
                            health and wellness
                            professional with 35 years
                            of expertise, our team
                            comprises highly
                            competent professionals
                            who have successfully
                            guided thousands towards
                            achieving their goals with
                            this protocol. With a
                            wealth of experience and
                            a proven track record, we
                            are dedicated to providing
                            top-notch guidance and
                            support to empower you
                            on your journey to
                            wellness and success.</p>

                        </div>
                    </div>

                    

                </div>
            </div>

        </div>

        </section> */}


        <section className="sec_padding why-choose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg">
                            <div className="content-top pr-md-5">
                                <h6 className="sub_heading">Value</h6>
                                <h2 className="sec_heading-stylish">Why Choose Us</h2>
                                <p className="title-pera pt-2 pb-3">{contentOne || "Default content for content-one..."}</p>
                            </div>
                            <img src="assets/images/why-choose-us.png" className="why-use-img" alt="why choose us" />
                        </div>
                        <div className="col-lg-3">
                            <div className="item-why-us one">
                                <div className="icon-why">
                                    <img src="assets/images/why-us-one.png" alt="" />
                                </div>
                                <p className="pera">{contentTwo || "Default content for content-two..."}</p>
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="item-why-us-right border-start border-theme">
                                <div className="item-why-us two border-bottom border-theme">
                                    <div className="icon-why">
                                        <img src="assets/images/why-us-two.png" alt="" />
                                    </div>
                                    <p className="pera">{contentThree || "Default content for content-three..."}</p>
                                </div>
                                <div className="item-why-us three">
                                    <div className="icon-why">
                                        <img src="assets/images/why-us-three.png" alt="" />
                                    </div>
                                    <p className="pera">{contentFour || "Default content for content-four..."}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhyChooseUs;