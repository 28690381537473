// LogoContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
    const [logoUrl, setLogoUrl] = useState("");
    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                if (response.data.photos && response.data.photos.length > 0) {
                    setLogoUrl(response.data.photos[0].url);
                }
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };

        fetchLogo();
    }, [API_URL_PHOTO]);

    return (
        <LogoContext.Provider value={logoUrl}>
            {children}
        </LogoContext.Provider>
    );
};
