import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [introText, setIntroText] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [testimonialImage, setTestimonialImage] = useState("");

    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const API_URL_TESTIMONIALS = `${BASE_URL}/api/v1/topic/175`;
    const API_URL_INTRO_TEXT = `${BASE_URL}/api/v1/topic/177`;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get(API_URL_TESTIMONIALS);
                setTestimonials(response.data.topic);
            } catch (err) {
                setError("Error fetching testimonials");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        const fetchIntroText = async () => {
            try {
                const response = await axios.get(API_URL_INTRO_TEXT);
                setIntroText(response.data.topic[0].details);
            } catch (err) {
                console.error("Error fetching intro text", err);
            }
        };

        const fetchTestimonialImage = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                setTestimonialImage(response.data.topic_photo);
            } catch (err) {
                console.error("Error fetching testimonial image", err);
            }
        };

        fetchTestimonials();
        fetchIntroText();
        fetchTestimonialImage();
    }, [API_URL_TESTIMONIALS, API_URL_INTRO_TEXT, API_URL_PHOTO]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <section className="sec_padding testimonial">
            <div className="container">
                <div className="row overflow-hidden">
                    <div className="col-md-6 col-lg-7">
                        <div className="content-top pr-md-5">
                            <h6 className="sub_heading">Testimonials</h6>
                            <h2 className="sec_heading-stylish">Best instructors in town!</h2>
                            <p className="title-pera pt-2 pb-3">
                                {introText}
                            </p>
                        </div>

                        <div className="testimonial-slide">
                            <OwlCarousel
                                className="owl-theme"
                                loop
                                margin={10}
                                nav
                                items={1}
                            >
                                {testimonials.map((testimonial, index) => (
                                    <div className="item-testimonial" key={index}>
                                        <p className="pera-testimonial">{testimonial.details}</p>
                                        <div className="user-box">
                                            <div className="img-user">
                                                <img src={testimonial.photo_file} alt={testimonial.title} />
                                            </div>
                                            <div className="content">
                                                <h5 className="user-name">{testimonial.title}</h5>
                                                <p className="destination">Manager</p>
                                                <div className="rating-icon">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="img-testimonial">
                            <img src={testimonialImage} alt="Testimonials" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
