import React, { useEffect, useState } from "react";
import axios from "axios";

const Transformation = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        axios.get("https://shapeshifting.ahatech1.cloud/api/v1/topic/181")
            .then((response) => {
                // Access the topic array and the fields within it
                setData(response.data.topic[0]);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    if (!data) return <p>Loading...</p>;

    // Map fields based on their title values to match component structure
    const titleOne = data.fields.find(field => field.title === "title-one");
    const contentOne = data.fields.find(field => field.title === "content-one");
    const titleTwo = data.fields.find(field => field.title === "title-two");
    const contentTwo = data.fields.find(field => field.title === "content-two");
    const titleThree = data.fields.find(field => field.title === "title-three");
    const contentThree = data.fields.find(field => field.title === "content-three");
    const titleFour = data.fields.find(field => field.title === "title-four");
    const contentFour = data.fields.find(field => field.title === "content-four");

    // Find images by their title for corresponding sections
    const imageOne = data.fields.find(field => field.title === "image-one")?.value;
    const imageThree = data.fields.find(field => field.title === "image-three")?.value;
    const imageFour = data.fields.find(field => field.title === "image-four")?.value;
    const introContent = data.fields.find(field => field.title === "Transformational Wellness!");


    return (
        <section className="sec_padding transformation">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-md-4 mb-3">
                        <h2 className="sec_heading-stylish text-center">{data.title}</h2>
                        <p className="title-pera-center">
                           {introContent?.value}
                        </p>
                    </div>
                </div>
                <div className="row tr-items">
                    <img src="assets/images/background-tr.webp" className="tr-img-bg" alt="" />
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-12 col-sm-6">
                                <div className="tr-box left">
                                    <div className="icon">
                                        <img src={`assets/images/${imageOne || "Lighten-the-Load.png"}`} alt="" />
                                    </div>
                                    <h4 className="title-tr">{titleOne?.value}</h4>
                                    <p className="pera">{contentOne?.value}</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="tr-box left">
                                    <div className="icon">
                                        <img src={`./assets/images/${imageThree || "making-changes.png"}`} alt="" />
                                    </div>
                                    <h4 className="title-tr">{titleThree?.value}</h4>
                                    <p className="pera">{contentThree?.value}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="img-tr">
                            <img src="./assets/images/wellness.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-12 col-sm-6">
                                <div className="tr-box right">
                                    <div className="icon">
                                        <img src={`./assets/images/${imageOne || "Find-new-ways.png"}`} alt="" />
                                    </div>
                                    <h4 className="title-tr">{titleTwo?.value}</h4>
                                    <p className="pera">{contentTwo?.value}</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="tr-box right">
                                    <div className="icon">
                                        <img src={`./assets/images/${imageFour || "choice-yours.png"}`} alt="" />
                                    </div>
                                    <h4 className="title-tr">{titleFour?.value}</h4>
                                    <p className="pera">{contentFour?.value}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Transformation;
