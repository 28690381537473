import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import axios from "axios";
import WhyChooseUs from "../components/WhyChooseUs";

const AboutPage = () => {
  const [aboutData, setAboutData] = useState(null);
  const [whyChooseUsData, setWhyChooseUsData] = useState(null);
  const [aboutsectionData, setAboutsectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topic/178`; // is for inner-banner-section about-top section
  const WHY_CHOOSE_US_API_URL = `${BASE_URL}/api/v1/topic/184`;
  const ABOUT_API_URL = `${BASE_URL}/api/v1/topic/190`;

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(API_URL);
        
        setAboutData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutData();
  }, [API_URL]);

  useEffect(() => {
    const fetchWhyChooseUsData = async () => {
      try {
        const response = await axios.get(WHY_CHOOSE_US_API_URL);
        setWhyChooseUsData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching Why Choose Us data");
        console.error(err);
      }
    };
    fetchWhyChooseUsData();
  }, [WHY_CHOOSE_US_API_URL]);

  useEffect(() => {
    const fetchAboutsectionData = async () => {
      try {
        const response = await axios.get(ABOUT_API_URL);
        setAboutsectionData(response.data.topic[0]);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutsectionData();
  }, [ABOUT_API_URL]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Safe access using optional chaining and default values
  const headingField = whyChooseUsData?.fields?.find(
    (field) => field.type === 0
  );
  const heading = headingField?.value || "Default Heading"; // Fallback if not found

  const stepsFields = whyChooseUsData?.fields?.filter(
    (field) => field.type !== 0
  );

  const paragraphOne = aboutsectionData?.fields?.find(
    (field) => field.title === "Paragraph_One"
  )?.value || "Default Paragraph One Text..."; // Fallback if not found

  const paragraphTwo = aboutsectionData?.fields?.find(
    (field) => field.title === "Paragraph_two"
  )?.value || "Default Paragraph Two Text..."; // Fallback if not found

  const imageMichele =
    aboutsectionData?.fields?.find((field) => field.type === 8)?.value || null; // Fallback if not found

  return (
    <>
      <Header />

      {/* ---bradrumb--start----  */}
      <section className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="active">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ---bradrumb--end----  */}

      {/* ----------banner-section--inner-page-start---  */}
      <section className="inner-banner-section about-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge">
                    {aboutData?.title || "My Philosophy"}
                  </h5>
                  <h1 className="main-heading mb-0">
                    {aboutData?.details?.match(/<h1>(.*?)<\/h1>/)?.[1] ||
                      "Transform Your Health: A 60-Day Metamorphosis with Michele Drake"}
                  </h1>
                </div>
                <p className="pera">
                  {aboutData?.details?.match(/<p>(.*?)<\/p>/)?.[1] ||
                    "Default description text..."}
                </p>
                <div className="d-flex gap-3 justify-content-between">
                  <Link to="/quiz" className="btn theme_btn">
                    Take a Quiz Now
                  </Link>
                  <Link to="/podcast" className="podcast-btn">
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                    <span>
                      <i className="fa fa-play"></i>
                    </span>{" "}
                    <p className="podcasr-text-none">Free Podcast</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <video
                  controls
                  src={
                    aboutData?.video_file ||
                    "https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section End */}
      {/* ----banner-section-inner-page-end-----  */}

      <section className="sec_padding about-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="about-img">
                <img
                  src={
                    imageMichele
                      ? `${BASE_URL}/uploads/topics/${imageMichele}`
                      : "assets/images/Michele.png"
                  }
                  alt="Michele"
                />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <h2 className="sec_heading m-0">
                Meet <span className="sec_heading-stylish">Michele</span>
              </h2>
              <p>{paragraphOne}</p> {/* Paragraph One */}
              <Link to="/quiz" className="btn theme_btn">
                Take a Quiz Now
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <p>{paragraphTwo}</p> {/* Paragraph Two */}
          </div>
        </div>
      </section>

      {/* -----Step Process------ */}
      <section className="sec_padding step-process">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4 mb-3">
              <h2 className="sec_heading-stylish text-center">
                4 Step Process
              </h2>
              <h6 className="sub_heading text-center">{heading}</h6>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
              <ul className="steps-container py-md-3">
                {stepsFields?.map((field, index) => (
                  <li key={index} className="step">
                    <span className="step-number">{index + 1}</span>
                    <h3 className="title">{field.title}</h3>
                    <p className="pera">{field.value}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 border-top pt-3 border-theme">
              <p className="pera">{whyChooseUsData?.details}</p>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <WhyChooseUs />
      <Footer />
    </>
  );
};

export default AboutPage;
