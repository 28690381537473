import React from "react";
import { Link } from "react-router-dom";

const Podcast = () => {
    return (
        <>

            <section className="podcast-items">
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6  col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                             </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6  col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                             </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span>  <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  
                {/* ----------podcast-item--inner-page-start---  */}
                    <div className="podcast-item">
                                
                                <div  className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-caption">
                                                <div>
                                                    <h5 className="text-banner-badge">Services</h5>
                                                    <h1 className="main-heading mb-0">Lighten the load – The Time is Now!</h1>
                                                </div>
                                                <p className="pera">
                                                While our primary focus is on helping you rapidly and safely release fat, our program offers holistic benefits for all. Michele provides limited spaces for 1:1 health coaching sessions to cultivate vibrancy and health in your life. She also can help you with proper form and brilliant results based fitness muscle conditioning programs. and choices into modalities of yoga, Pilates and more. Choose from a range of workshops and monthly transformational events to foster mindfulness and wellness. 
                                                </p>
                                                <div className="d-flex gap-3 justify-content-between">
                                                <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                                                    <Link to="/podcast" className="podcast-btn">
                                                                <div className="waves wave-1"></div>
                                                                <div className="waves wave-2"></div>
                                                                <div className="waves wave-3"></div>

                                                    <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="banner-img">
                                                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                {/* ----podcast-item-inner-page-end-----  */}  

                {/* --pagination-start----  */}
                    <div className="pagination">
                        <Link to="#"><i className="fa-solid icon disabled pre fa-arrow-left"></i></Link>
                        <ul className="pagination-items">
                        <Link to="#"> <li className="item active">1</li></Link>
                        <Link to="#"> <li className="item">2</li></Link>
                        <Link to="#"> <li className="item">3</li></Link>
                        <Link to="#"> <li className="item">4</li></Link>
                        </ul>
                        <Link to="#"><i className="fa-solid icon next fa-arrow-right"></i></Link>
                    </div>
                {/* --pagination-start----  */}
            </section>

        </>
    );
};

export default Podcast;
