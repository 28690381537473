import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ShopPage = () => {
    return (
        <>
          <Header />
             {/* ---bradrumb--start----  */}

             <section className="breadcrumb">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Shop</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---bradrumb--end----  */}


            {/* ---Product Listing page-start ----  */}

                <section className="product-listing sec_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 border-end mb-3">
                                <div className="row shop-sidebar">
                                    <div className="col-12 mb-4">
                                    <h2 className="sec_heading pb-2 mb-0 border-bottom">
                                    Category <span>(6)</span>
                                    </h2>
                                    </div>
                                    <div className="col-12">
                                    <form action="#">
                                    <ul className="product-category">
                                        <li className="category-item">
                                            <input type="checkbox" name="category-one" id="category-one"/><label for="category-one">General Category (36)</label>
                                        </li>
                                        <li className="category-item">
                                            <input type="checkbox" name="category-two" id="category-two"/><label for="category-two">Fitness Equipment & Wellness Tools (11)</label>
                                        </li>
                                        <li className="category-item">
                                            <input type="checkbox" name="category-three"id="category-three"/><label for="category-three">Kitchen Essentials for Healthy Living (36)</label>
                                        </li>
                                        <li className="category-item">
                                            <input type="checkbox" name="category-four"id="category-four"/><label for="category-four">Rapid Fat Loss & Biohacking Essentials (36)</label>
                                        </li>
                                        <li className="category-item">
                                            <input type="checkbox" name="category-five"id="category-five"/><label for="category-five">Self-Growth & Business
                                            Development Resources (36)</label>
                                        </li>
                                        <li className="category-item">
                                            <input type="checkbox" name="category-six"id="category-six"/><label for="category-six">Transformative Self Care (36)</label>
                                        </li>
                                    </ul>
                                </form>
                                    </div>
                                </div>
                               <div className="">
                                <div className="mb-2">
                                    
                                </div>
                               
                               </div>


                            </div>
                            <div className="col-md-9 ">
                                <div className="d-flex pb-2 mb-4 gap-2 border-bottom justify-content-between align-items-center flex-wrap">
                                    <h2 className="sec_heading mb-0">
                                    Product List <span>(54)</span>
                                    </h2>

                                    <div className="searchbar">
                                        <input type="search" className="search-input" placeholder="Search"/>
                                        <div className="icon-submit"><i className="fas fa-search"></i></div>
                                    </div>
                                </div>

                                <div className="row g-1 g-sm-4">
                                   
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-1.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                Dandy Blend Instant 
                                                Herbal Beverage 
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $23.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-2.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                Lakanto Classic Monkfruit Sweetener 
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $35.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-3.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                Traditional Medicinals – Organic Smooth 
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $6.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-4.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                CanPrev D3 & K2 | Organic Coconut Oil |
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $40.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-5.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                Belula Premium Dry Brushing Body Brush 
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $32.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <Link to="#" className="product-box">
                                            <div className="product-img">
                                                <img src="/assets/images/products/product-6.png" alt=""/>
                                            </div>
                                            <div className="product-content">
                                                <h2 className="product-title">
                                                NANO Baja Gold Sea Salt Organic
                                                </h2>
                                               <div className="product-rating">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <span className="rating-number">(540)</span>
                                               </div>
                                               <p className="product-rate">
                                               $.81.00
                                               </p>
                                               <Link to="#" className="shop-btn btn theme_btn">
                                                    Shop <span className="d-none d-sm-inline-block">Now</span>
                                               </Link>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-12 pt-5">
                                        {/* --pagination-start----  */}
                                        <div className="pagination">
                                            <Link to="#"><i className="fa-solid icon disabled pre fa-arrow-left"></i></Link>
                                            <ul className="pagination-items">
                                            <Link to="#"> <li className="item active">1</li></Link>
                                            <Link to="#"> <li className="item">2</li></Link>
                                            <Link to="#"> <li className="item">3</li></Link>
                                            <Link to="#"> <li className="item">4</li></Link>
                                            </ul>
                                            <Link to="#"><i className="fa-solid icon next fa-arrow-right"></i></Link>
                                        </div>
                                    {/* --pagination-start----  */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            {/* ---Product Listing page-end ----  */}


          <Footer />
        </>
    );
};

export default ShopPage;
