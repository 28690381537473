import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Banner = () => {
    
    const [bannerData, setBannerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define the API URL using environment variable (APP_URL + API endpoint)
    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const API_URL = `${BASE_URL}/api/v1/topic/169`;

    // Fetch data when the component is mounted
    useEffect(() => {
        AOS.init(); // Initialize AOS animations

        const fetchBannerData = async () => {
            try {
                const response = await axios.get(API_URL);
                setBannerData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBannerData();
    }, [API_URL]);

    // If loading, show a loading message
    if (loading) {
        return (
            <section className="home-banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>Loading...</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // If there's an error, show an error message
    if (error) {
        return (
            <section className="home-banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // If banner data is available, display it
    return (
        <section className="home-banner-section">
            <img src="assets/images/backgrond-home.png" className="background-hero" alt="Background" />
            <div className="container">
                <div className="row">
                    <div data-aos="fade-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge">{bannerData?.topic[0]?.title || 'Transformation Guide'}</h5>
                                <h1 className="main-heading mb-0">{bannerData?.topic[0]?.details?.match(/<h1>(.*?)<\/h1>/)?.[1] || 'Shape Shifting Mastery: Rapid & Safe Fat Release Program'}</h1> {/* Dynamically set title */}
                            </div>
                            <p className="pera">
                                {bannerData?.topic[0]?.details?.match(/<p>(.*?)<\/p>/)?.[1] || 'Embark on a holistic transformation journey with our Shape-Shifting Mastery program, integrating biohacking principles for transformative self-care.'}
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                               <Link to="/quiz" className="btn theme_btn">Take a Quiz Now</Link>
                               <Link to="/podcast" className="podcast-btn">
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>

                                <span><i className="fa fa-play"></i></span> <p className="podcasr-text-none">Free Podcast</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            {/* Display the video URL if available, else use a default video */}
                            <video controls src={bannerData?.topic[0]?.video_file || "https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4"} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
