import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
// import Podcast from "../components/Podcast";

const PodcastDetailPage = () => {
    return (
        <>
        
            <Header />
            {/* ---bradrumb--start----  */}

            <section className="breadcrumb">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Podcast</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Podcast Detail</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---bradrumb--end----  */}
            <section className="podcast-detail sec_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="sec_heading text-center">Transform Your Health: A 60-Day Metamorphosis with Michele Drake</h2>
                            <p className="pera text-center">Are you ready to transform your health and reclaim your vitality? Join Michele Drake, a seasoned expert with over 35 years in alternative health and wellness, on a 60-day journey that will change your life. Discover a unique protocol that resets your body’s set point, ensuring sustainable weight loss and a renewed sense of well-being. Say goodbye to diets and hello to a healthier, happier you. Find out more about this transformative metamorphosis today!</p>
                        </div>
                        <div className="col-12">
                            <div className="detail-podcast-video">
                            <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                        <Link to="/quiz" className="btn theme_btn">BOOK A DISCOVERY CALL</Link>
                        </div>
                    </div>
                </div>
                    
            </section>

            <section className="sec_padding podcast-detail-content">

                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-md-5">
                            <h2 className="sec_heading-stylish text-center">Services</h2>
                            <span className="down-arrow"><i className="fa-solid fa-arrow-down-long"></i></span>
                        </div>
                        <div className="col-lg-5">
                                    <div className="content-service">
                                    <h1 className="main-heading mb-0">Personal Training & 7 Minute
                                        Miracles</h1>
                                    <p className="pera">
                                    Ready to prioritize your longevity and well-being? Experience the
                                    transformative power of personalized personal training with nutritional
                                    guidance by Michele Drake. Discover the importance of muscle as your
                                    longevity organ while exploring a vast array of modalities, including
                                    bodyweight training, weight training, yoga, Pilates, and core exercises.
                                    With tailored workout plans and expert nutritional support, you’ll not
                                    only build strength but also optimize your health for the long haul. Get
                                    ready to unleash your potential and achieve lasting results.
                                    </p>
                                    <ul className="list-with-check">
                                        <li>
                                            <i className="fa fa-check"></i>
                                            <span className="pera">Personalized workout plans targeting your specific goals and
                                            needs.</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check"></i>
                                            <span className="pera">Expert guidance on nutrition to fuel your body for optimal
                                            performance.</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check"></i>
                                            <span className="pera">Dedicated support and motivation to keep you on track and
                                            achieve lasting success.</span>
                                        </li>
                                    </ul>
                                    <Link to="/quiz" className="btn theme_btn">Learn More</Link>
                                    </div>
                        </div>
                        <div className="col-lg-7 mt-3 mt-lg-0 mt-3 mt-sm-4">
                            <div className="img-right top">
                                <img src="assets/images/service.png" alt=""/>
                            </div>
                        </div>
                    
                    <div className="col-12 text-center mt-3">                    
                            <Link to="/quiz" className="btn theme_btn"> <Link to="/quiz" className="btn theme_btn">BOOK A DISCOVRY CALL</Link></Link>
                    </div>
                        
                    </div>
                </div>

            </section>
            <Footer />
        </>
    );
};

export default PodcastDetailPage;
