import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section className="contact-contaier sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content-contact">
                <h2 className="sec_heading">Contact Me</h2>
                <p className="pera">
                  Curabitur fermentum nulla non justo aliquet, quis vehicula
                  quam consequat. Duis ut hendrerit tellus, elementum lacinia
                  elit. Maecenas at consectetur ex, vitae consequat augue.
                  Vivamus eget dolor vel quam condimentum sodales. In bibendum
                  odio urna, sit amet fermentum purus venenatis amet.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <form className="contact-form">
                <h2 className="sec_heading-stylish">Get in Touch</h2>

                <div className="fields">
                  <input
                    type="text"
                    className="theme_input"
                    name="name"
                    placeholder="Your Name"
                  />
                  <input
                    type="email"
                    className="theme_input"
                    name="email"
                    placeholder="Your Email"
                  />
                  <textarea
                    name="msg"
                    className="theme_input"
                    placeholder="Type Message here"
                    rows="5"
                    id="Msg"
                  ></textarea>
                  <input
                    type="submit"
                    className="btn theme_btn"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="contact-bg-center">
                <img
                  src="/assets/images/contact-plan.png"
                  className="img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="we-are-here sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h2 className="sec_heading">We are here for you.</h2>

              <Link to="#" className="box-contact mt-3 mt-sm-5">
                <div className="icon">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Location</h5>
                  <p className="pera">Canada</p>
                </div>
              </Link>
              <Link to="#" className="box-contact mt-3">
                <div className="icon">
                  <i className="fa-solid fa-clock"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Hours</h5>
                  <p className="pera">Monday – Friday: 11 am – 9:00 pm</p>
                  <p className="pera">Saturday – Sunday: 11 am – 5:00 pm</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-6">
              <Link to="#" className="box-contact mt-3 mt-sm-0">
                <div className="icon">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Email</h5>
                  <p className="pera">masterhabits@gmail.com</p>
                </div>
              </Link>
              <Link to="#" className="box-contact mt-3">
                <div className="icon">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Phone</h5>
                  <p className="pera">+19876765676</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
