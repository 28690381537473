import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const LoginPage = () => {
    return (
        <>
        <div className="form_container">
        <div className="container-fluid">

          <div className="row d-flex row_form justify-content-center align-items-center flex-wrap-reverse">
            <div className="col-lg-6 form_bg col-lg-6 p-0 m-0">
              <div className="form_main">


                <div className="form">
                  <div className="row heading text-center col-12">
                    <div class="logo-auth">
                      <img src="/logo.png" alt=""/>
                    </div>
                    <h2>Welcome Back</h2>
                    <p>The faster you fill, the faster you get</p>
                  </div>

                  <form id="form_logIn" >
                    
                    <div className="row">

                      <div className="col-12">
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="col-12 form_input">
                        <input type="text" placeholder="Enter Your Email" id="email" name='email'/>
                         {/* <small className="requied">required</small> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="password">Password</label>
                      </div>
                      <div className="col-12 form_input">
                        <input type="password" placeholder="Enter Your Password" id="password"  /> 
                        {/* <small className="requied">required</small> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 pe-0">

                        <div className="sb-checkbox">
                          <input type="checkbox" className="sb-checkbox__input" id="check1" name="check1" />
                          <label className="sb-checkbox__label sb-checkbox__label--green" htmlFor="check1">Remember Me </label>
                        </div>
                      </div>
                      <div className="col-6 ps-0 text-end">
                        <span>
                          <Link to={"#"}>Forget Password</Link>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <input
                          className="btn w-100 theme_btn"
                          value="Login"
                          type="submit"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <span className="subscription-info">
                          Don't have account ?
                          <b style={{ paddingLeft:'5px' }}>
                            <Link to="#" className="subscription-link">Register Now</Link>
                          </b>
                        </span>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block p-0 col-md-6 form_img_container">
              <div className="form-img">
                <Link to={``}><img loading="lazy" src="assets/images/login-poster.png" alt="form_img" /></Link>
              </div>

            </div>
          </div>
        </div>
      </div>
        </>
    );
};

export default LoginPage;
    