import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { Link } from "react-router-dom";

const ContactPage = () => {
    return (
        <>
          <Header />
          
             {/* ---bradrumb--start----  */}

             <section className="breadcrumb">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Contact Us</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---bradrumb--end----  */}
          <Contact />
            
            <Footer />
        </>
    );
};

export default ContactPage;
