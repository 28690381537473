import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ShopPage = () => {
    return (
        <>
          <Header />
             {/* ---bradrumb--start----  */}

             <section className="breadcrumb">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" >Shop</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Dandy Blend Instant Herbal Beverage with Dandelion – Organic 3.53 oz (100 Grams) Pkg</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---bradrumb--end----  */}


            {/* ---product-detail page-start ----  */}

                <section className="product-detail-container sec_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 ">

                                <div className="product-img-lg">
                                    <img src="assets/images/products/product-one-main.png" alt=""/>
                                </div>
                               
                               <ul className="product-sub-imgs">
                                <li className="img-sub active">

                                    <img src="assets/images/products/product-1.png" alt=""/>

                                </li>
                                <li className="img-sub">

                                    <classNameimg src="assets/images/products/product-1.png" alt=""/>

                                </li>
                                <li className="img-sub">

                                    <img src="assets/images/products/product-1.png" alt=""/>

                                </li>
                                <li className="img-sub">

                                    <img src="assets/images/products/product-1.png" alt=""/>

                                </li>
                                <li className="img-sub">

                                    <img src="assets/images/products/product-1.png" alt=""/>

                                </li>

                               </ul>

                            </div>

                            <div className="col-sm-6 mt-3 mt-sm-0">
                                <div className="product-details">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <h4 className="product-category">General Category</h4>
                                        <div className="product-rating">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <span className="rating-number">(540)</span>
                                        </div>
                                    </div>
                                    <h2 className="prodcut-name">Dandy Blend Instant Herbal Beverage with Dandelion – Organic 3.53 oz (100 Grams) Pkg</h2>

                                    <div className="product-count">

                                        <span className="product-minus"><i className="fa fa-minus"></i></span>

                                        <input type="number" name="product-count" value="2" className="product-count" id="product-count"/>
                                        <span className="product-plus"><i className="fa fa-plus"></i></span>
                                    </div>

                                    <p className="product-prize">$23.00</p>

                                    <Link to="#" className="btn theme_btn">Shop Now</Link>
                                </div>
                            </div>

                            <div className="col-12 mt-3 mt-md-5">
                            <h2 className="sec_heading">Related Products</h2>
                            <p className="pera product-desc">Dandy Blend Instant Herbal Beverage with Dandelion is a rich, smooth, and caffeine-free alternative to coffee. Made from roasted dandelion root and other premium herbal ingredients, this organic blend provides a bold, coffee-like flavor without the jitters or acidity associated with traditional coffee. Perfect for anyone looking to enjoy a warm, satisfying drink any time of day.</p>
                            </div>
                        </div>
                    </div>
                </section>

            {/* ---product-detail page-end ----  */}

            <hr></hr>


            {/* ----related-products-start------ */}

          <section className="ralated-products sec_padding">
            <div className="container">
            <div className="row g-1 g-sm-4">

                <div className="col-12">
                    <h2 className="sec_heading">Related Products</h2>
                </div>
                                   
                <div className="col-md-3 col-6">
                    <Link to="#" className="product-box">
                        <div className="product-img">
                            <img src="/assets/images/products/product-1.png" alt=""/>
                        </div>
                        <div className="product-content">
                            <h2 className="product-title">
                            Dandy Blend Instant 
                            Herbal Beverage 
                            </h2>
                            <div className="product-rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <span className="rating-number">(540)</span>
                            </div>
                            <p className="product-rate">
                            $23.00
                            </p>
                            <Link to="#" className="shop-btn btn theme_btn">
                                Shop <span className="d-none d-sm-inline-block">Now</span>
                            </Link>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6">
                    <Link to="#" className="product-box">
                        <div className="product-img">
                            <img src="/assets/images/products/product-2.png" alt=""/>
                        </div>
                        <div className="product-content">
                            <h2 className="product-title">
                            Lakanto Classic Monkfruit Sweetener 
                            </h2>
                            <div className="product-rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <span className="rating-number">(540)</span>
                            </div>
                            <p className="product-rate">
                            $35.00
                            </p>
                            <Link to="#" className="shop-btn btn theme_btn">
                                Shop <span className="d-none d-sm-inline-block">Now</span>
                            </Link>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6">
                    <Link to="#" className="product-box">
                        <div className="product-img">
                            <img src="/assets/images/products/product-3.png" alt=""/>
                        </div>
                        <div className="product-content">
                            <h2 className="product-title">
                            Traditional Medicinals – Organic Smooth 
                            </h2>
                            <div className="product-rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <span className="rating-number">(540)</span>
                            </div>
                            <p className="product-rate">
                            $6.00
                            </p>
                            <Link to="#" className="shop-btn btn theme_btn">
                                Shop <span className="d-none d-sm-inline-block">Now</span>
                            </Link>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6">
                    <Link to="#" className="product-box">
                        <div className="product-img">
                            <img src="/assets/images/products/product-4.png" alt=""/>
                        </div>
                        <div className="product-content">
                            <h2 className="product-title">
                            CanPrev D3 & K2 | Organic Coconut Oil |
                            </h2>
                            <div className="product-rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <span className="rating-number">(540)</span>
                            </div>
                            <p className="product-rate">
                            $40.00
                            </p>
                            <Link to="#" className="shop-btn btn theme_btn">
                                Shop <span className="d-none d-sm-inline-block">Now</span>
                            </Link>
                        </div>
                    </Link>
                </div>
                
            </div>
            </div>
          </section>


            

            {/* ----related-products-end------ */}


          <Footer />
        </>
    );
};

export default ShopPage;
